import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer, useLocalStore } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import useDidMount from '@root/hooks/useDidMount';
import useDidUpdate from '@root/hooks/useDidUpdate';

import PaymentCardStore from '@root/stores/PaymentCardStore';

import ImtuTxnDetailsLooperStore from '@components/modules/ImtuReceipt/ImtuTxnDetailsLooperStore';

import ROUTE_PATHS from '@root/routes/paths';

import {
  PageTitle,
  AdaptiveHelpButton,
  Spinner,
  Grid,
  AdaptiveCancelButton,
} from '@components/common';

import Receipt from './components/Receipt';
import BottomControls from './components/BottomControls';

import { getImtuReceiptPageTitle } from './helpers';

interface Props {
  txnId: string;
}

const DATA_TEST_ID_PREFIX = 'mtu';

const ImtuReceipt: React.FunctionComponent<Props> = (props: Props) => {
  const { txnId } = props;

  const { t } = useTranslation();

  const history = useHistory();

  const imtuTxnDetailsLooperStore = useLocalStore(() => new ImtuTxnDetailsLooperStore());
  const paymentCardStore = useLocalStore(() => new PaymentCardStore());

  useDidMount(() => {
    if (txnId) {
      imtuTxnDetailsLooperStore.updateTxnStatusInLoop(txnId);
    }
  });

  useDidUpdate(() => {
    const handleEditableCardRetrieval = async (cardId: string) => {
      try {
        await paymentCardStore.getEditableCardByHandleId(cardId);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('unhandled error', err);
      }
    };
    if (imtuTxnDetailsLooperStore.txn) {
      handleEditableCardRetrieval(imtuTxnDetailsLooperStore.txn.paymentCardId);
    }
  }, [imtuTxnDetailsLooperStore.txn]);

  const handleCancelClick = () => {
    history.push(ROUTE_PATHS.ACCOUNT);
  };

  return (
    <Spinner isSpinning={!imtuTxnDetailsLooperStore.txn}>
      <PageTitle
        className="print:hidden"
        title={t(getImtuReceiptPageTitle(imtuTxnDetailsLooperStore.txn?.status) || '')}
        buttonsNode={
          <>
            <AdaptiveHelpButton />
            <AdaptiveCancelButton
              onClick={handleCancelClick}
              dataTestId={`${DATA_TEST_ID_PREFIX}-cancel-btn`}
            />
          </>
        }
        hasFixedPosition
      />
      <Grid.Container className="flex flex-col p-4 gap-6 mb-32">
        <Grid.Row>
          <Grid.Col
            span={{ sm: 12, lg: 4 }}
            offset={{ lg: 4 }}
            className="flex justify-center w-96"
          >
            {imtuTxnDetailsLooperStore.txn ? (
              <>
                <Receipt
                  txnStatus={imtuTxnDetailsLooperStore.txn.status}
                  countryCode={imtuTxnDetailsLooperStore.txn.recipientCountryCode}
                  recipientPhoneNumber={
                    imtuTxnDetailsLooperStore.txn.recipientPhoneNumber
                  }
                  carrier={imtuTxnDetailsLooperStore.txn.carrier}
                  product={imtuTxnDetailsLooperStore.txn.product}
                  paymentInfo={paymentCardStore.editableCard}
                  txnErrorCode={imtuTxnDetailsLooperStore.txn.errorCode}
                />
                <BottomControls />
              </>
            ) : null}
          </Grid.Col>
        </Grid.Row>
      </Grid.Container>
    </Spinner>
  );
};

export default observer(ImtuReceipt);
