import { makeAutoObservable } from 'mobx';

import { showToast } from '@services/toasts';

import PaymentCardConfig from '@root/models/PaymentCardConfig';

import {
  AddPaymentCardRequest,
  AddPaymentCardWithoutBillingRequest,
  UpdatePaymentCardRequest,
  UpdatePaymentCardWithouBillingRequest,
} from '@root/interfaces/contract/PaymentCards';

import { PaymentCardIssuerKeys } from '@root/interfaces/PaymentCard';

import {
  addPaymentCard,
  fetchPaymentCardsConfig,
  removePaymentCardByHandleId,
  updatePaymentCardByHandleId,
  fetchPaymentCardByHandleId,
} from '@services/api/v1/paymentCards';
import PaymentCardClass from '@root/models/PaymentCardClass';

class PaymentCardStore {
  isLoading = false;

  config?: PaymentCardConfig;

  editableCard: PaymentCardClass | null = null;

  issuer?: PaymentCardIssuerKeys;

  constructor() {
    makeAutoObservable(this);
  }

  getPaymentCardConfigs = async () => {
    this.isLoading = true;
    try {
      this.config = await fetchPaymentCardsConfig();
    } catch (err) {
      // TODO: remove toast from the store (see example of getEditableCardByHandleId)
      showToast.error(err.message);
    } finally {
      this.isLoading = false;
    }
  };

  submitNewCard = async (
    values: AddPaymentCardRequest | AddPaymentCardWithoutBillingRequest,
  ) => {
    this.isLoading = true;
    try {
      const res = await addPaymentCard(values);
      return res;
    } catch (err) {
      // TODO: remove toast from the store (see example of getEditableCardByHandleId)
      showToast.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }
  };

  updateCard = async (
    values: UpdatePaymentCardRequest | UpdatePaymentCardWithouBillingRequest,
  ) => {
    this.isLoading = true;
    try {
      return await updatePaymentCardByHandleId(values);
    } catch (err) {
      // TODO: remove toast from the store (see example of getEditableCardByHandleId)
      showToast.error(err.message);
      throw err;
    } finally {
      this.isLoading = false;
    }
  };

  getEditableCardByHandleId = async (id: string) => {
    this.isLoading = true;
    try {
      const res = await fetchPaymentCardByHandleId(id);
      this.editableCard = res;
    } finally {
      this.isLoading = false;
    }
  };

  getCardIssuer = (cardNumber: string) => {
    return this.config?.getIssuerKeyByCardNumber(cardNumber);
  };

  removeCardByHandleId = async (id: string) => {
    this.setIsLoading(true);
    try {
      await removePaymentCardByHandleId(id);
    } catch (err) {
      // TODO: remove toast from the store (see example of getEditableCardByHandleId)
      showToast.error(err.message);
      throw err;
    } finally {
      this.setIsLoading(false);
    }
  };

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  setIssuer = (value: PaymentCardIssuerKeys | undefined) => {
    this.issuer = value;
  };

  clearEditableCard = () => {
    this.editableCard = null;
  };
}

export default PaymentCardStore;
