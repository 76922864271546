import React, { FC } from 'react';

import { normalizeStringCompound } from '@utils/string';

import IconProps from '@root/interfaces/components/Icon';

import SvgComponent from '@components/common/Icon/SvgComponent';

const Icon: FC<React.PropsWithChildren<IconProps>> = (props) => {
  const {
    name,
    className,
    width,
    height,
    onClick,
    disabled,
    arialLabel,
    dataTestId,
    tabIndex,
    title,
    viewBox,
    hasNoWrapper,
  } = props;

  const svgProps = {
    width,
    height,
    viewBox,
    name,
  };

  const svgComponent = <SvgComponent {...svgProps} />;

  const classNames = normalizeStringCompound([
    'inline-flex items-center normal-case',
    className,
  ]);

  if (onClick) {
    return (
      <button
        arial-label={arialLabel}
        className={classNames}
        onClick={onClick}
        disabled={disabled}
        type="button"
        data-test-id={dataTestId}
        title={title}
        tabIndex={tabIndex}
      >
        {svgComponent}
      </button>
    );
  }
  if (hasNoWrapper) {
    return svgComponent;
  }
  return (
    <span role="img" arial-label={arialLabel} className={classNames}>
      {svgComponent}
    </span>
  );
};

Icon.defaultProps = {
  width: '1em',
  height: '1em',
  hasNoWrapper: false,
};

export default Icon;
