// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../static/assets/fonts/IDTSans/IDTSans-ExtraLight/IDT_Sans_ExtraLight.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../static/assets/fonts/IDTSans/IDTSans-ExtraLight/IDT_Sans_ExtraLight.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../static/assets/fonts/IDTSans/IDTSans-Light/IDT_Sans_Light.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../static/assets/fonts/IDTSans/IDTSans-Light/IDT_Sans_Light.woff");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../static/assets/fonts/IDTSans/IDTSans-Regular/IDT_Sans_Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../static/assets/fonts/IDTSans/IDTSans-Regular/IDT_Sans_Regular.woff");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../static/assets/fonts/IDTSans/IDTSans-Medium/IDT_Sans_Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../static/assets/fonts/IDTSans/IDTSans-Medium/IDT_Sans_Medium.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../static/assets/fonts/IDTSans/IDTSans-DemiBold/IDT_Sans_DemiBold.woff2");
var ___CSS_LOADER_URL_IMPORT_9___ = require("../static/assets/fonts/IDTSans/IDTSans-DemiBold/IDT_Sans_DemiBold.woff");
var ___CSS_LOADER_URL_IMPORT_10___ = require("../static/assets/fonts/IDTSans/IDTSans-Bold/IDT_Sans_Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_11___ = require("../static/assets/fonts/IDTSans/IDTSans-Bold/IDT_Sans_Bold.woff");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
var ___CSS_LOADER_URL_REPLACEMENT_9___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_9___);
var ___CSS_LOADER_URL_REPLACEMENT_10___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_10___);
var ___CSS_LOADER_URL_REPLACEMENT_11___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_11___);
// Module
exports.push([module.id, "@font-face{font-family:\"IDTSans\";font-display:swap;font-weight:200;font-style:\"normal\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\")}@font-face{font-family:\"IDTSans\";font-display:swap;font-weight:300;font-style:\"normal\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\")}@font-face{font-family:\"IDTSans\";font-display:swap;font-weight:400;font-style:\"normal\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"woff\")}@font-face{font-family:\"IDTSans\";font-display:swap;font-weight:500;font-style:\"normal\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\")}@font-face{font-family:\"IDTSans\";font-display:swap;font-weight:600;font-style:\"normal\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_9___ + ") format(\"woff\")}@font-face{font-family:\"IDTSans\";font-display:swap;font-weight:700;font-style:\"normal\";src:url(" + ___CSS_LOADER_URL_REPLACEMENT_10___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_11___ + ") format(\"woff\")}", ""]);
// Exports
module.exports = exports;
