import React, { FC, useState } from 'react';
import { isPast, isSameDay } from 'date-fns';
import InputMask from 'react-input-mask';
import { useTranslation } from 'react-i18next';

import { CARD_EXP_DATE_FORMAT } from '@root/constants/paymentCards/paymentCardsData';
import { CVV_INPUT_MASK } from '@root/constants/inputMasks';

import { normalizeStringCompound } from '@utils/string';

import { parseDateFromExpDateString } from '@helpers/paymentCards';

import { ReactComponent as EvmImage } from '@src/static/assets/images/evm.svg';
import { ReactComponent as VisaImage } from '@src/static/assets/images/visa.svg';
import { ReactComponent as MasterCardImage } from '@src/static/assets/images/mastercard.svg';
import { ReactComponent as AmExImage } from '@src/static/assets/images/amex.svg';
import { ReactComponent as EyeIcon } from '@src/static/assets/images/icons/legacyIcons/legacy-eye-alt.svg';
import { ReactComponent as EyeOffIcon } from '@src/static/assets/images/icons/legacyIcons/legacy-eye-off-alt.svg';

import { Icon, Input } from '@components/common';

import {
  PaymentCardProps,
  PaymentCardIssuer,
} from '@root/interfaces/components/PaymentCard';

import './styles.scss';

const BASE_SELECTOR = 'payment-card';
const ANIMATION_BASE_SELECTOR = 'flipping-inner';

const PaymentCard: FC<React.PropsWithChildren<PaymentCardProps>> = (props) => {
  const {
    isCvvVisible,
    className,
    holderName,
    maskedCardNumber,
    expDateString,
    issuer,
    cvvNumber,
    onChangeCvv,
    dataTestId,
    isActive,
  } = props;

  const { t } = useTranslation();

  const [isCvvInputMasked, setIsCvvInputMasked] = useState(false);

  const animationClassNames = normalizeStringCompound([
    ANIMATION_BASE_SELECTOR,
    isCvvVisible && issuer !== 'AE' ? `${ANIMATION_BASE_SELECTOR}_flipped` : undefined,
  ]);

  const cardClassNames = normalizeStringCompound([
    isActive ? 'bg-white' : 'bg-gray-200',
    'rounded-md overflow-hidden shadow-md',
  ]);

  const labelClassNames = 'text-lg text-gray-400 font-medium';

  const expDate = parseDateFromExpDateString(expDateString);
  const isExpiredCard = expDate
    ? isPast(expDate) && !isSameDay(expDate, new Date())
    : false;

  const cvvInputClassNames =
    'h-full w-20 text-center rounded-md border border-gray-400 bg-gray-300 ml-3 flex-shrink-0';

  const isCvvInputDisabled = !onChangeCvv;

  const issuerImage: { [key in PaymentCardIssuer]: React.ReactNode } = {
    MC: <MasterCardImage className="w-16" />,
    V: <VisaImage className="w-16" />,
    AE: <AmExImage className="w-16" />,
    D: null,
  };

  const getCardValueClassNames = (value?: string) => {
    const textColor = value ? 'text-gray-900' : 'text-gray-400';
    return `${textColor} text-xl`;
  };

  const handleMouseDown = () => {
    setIsCvvInputMasked(true);
  };

  const handleMouseUp = () => {
    setIsCvvInputMasked(false);
  };

  const handleTouchStart = () => {
    setIsCvvInputMasked(true);
  };

  const handleTouchEnd = () => {
    setIsCvvInputMasked(false);
  };

  const cvvInputAddonAfter = (
    <button
      tabIndex={-1}
      className="h-full pr-1"
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
    >
      {isCvvInputMasked ? <EyeOffIcon /> : <EyeIcon />}
    </button>
  );

  return (
    <div className={normalizeStringCompound([BASE_SELECTOR, className])}>
      <div className={animationClassNames}>
        <div
          className={normalizeStringCompound([
            cardClassNames,
            `${ANIMATION_BASE_SELECTOR}__front`,
            'z-0',
          ])}
        >
          <div className="relative flex justify-between h-full px-8 py-6">
            {isExpiredCard && (
              <div className="absolute flex items-center justify-center text-red-400 border-2 border-red-400 rounded-full w-7 h-7 top-5">
                <Icon name="Warning" width="2rem" height="2rem" />
              </div>
            )}
            <div className="flex flex-col justify-end">
              <EvmImage className="mb-5 w-14 md:mb-7 md:w-16" />
              <div className={labelClassNames}>{t('Card Number')}</div>
              <div
                className={getCardValueClassNames(maskedCardNumber)}
                data-recording-sensitive
              >
                {maskedCardNumber || 'XXXX XXXX XXXX XXXX'}
              </div>
              <div className={labelClassNames}>{t('Cardholder Name')}</div>
              <div
                className={getCardValueClassNames(holderName)}
                data-recording-sensitive
              >
                {holderName || t('Your Name')}
              </div>
            </div>
            <div className="flex flex-col justify-between text-right">
              <div className="flex flex-col items-end">
                {issuer ? issuerImage[issuer] : null}
                {issuer === 'AE' ? (
                  <div
                    className={`mt-2 ${getCardValueClassNames(cvvNumber)}`}
                    data-recording-sensitive
                  >
                    {cvvNumber}
                  </div>
                ) : null}
              </div>
              <div>
                <div className={labelClassNames}>{t('Valid Thru')}</div>
                <div
                  className={
                    isExpiredCard
                      ? 'text-red-400 text-xl'
                      : getCardValueClassNames(expDateString)
                  }
                  data-recording-sensitive
                >
                  {expDateString || CARD_EXP_DATE_FORMAT.toUpperCase()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={normalizeStringCompound([
            cardClassNames,
            `${ANIMATION_BASE_SELECTOR}__back`,
          ])}
        >
          <div>
            <div className="w-full h-12 mt-10 bg-black-200" />
            <div className="p-6">
              <div className="flex h-12 mb-3">
                <div className="flex-shrink-0 w-3/4 h-full mr-3 bg-white rounded-md" />
                <InputMask
                  mask={CVV_INPUT_MASK}
                  maskChar=""
                  value={cvvNumber}
                  onChange={onChangeCvv}
                  disabled={isCvvInputDisabled}
                  className={cvvInputClassNames}
                >
                  {() => (
                    <Input
                      type={isCvvInputMasked ? 'text' : 'password'}
                      className="cvvInput important:px-1"
                      autoFocus={!isCvvInputDisabled}
                      isSecureContent
                      dataTestId={dataTestId ? `${dataTestId}-input` : undefined}
                      addonAfter={cvvInputAddonAfter}
                    />
                  )}
                </InputMask>
              </div>
              <div className="font-medium text-right text-gray-400 text-md">
                {t('Security Code (CVV)')}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isExpiredCard && (
        <div className="mt-1 text-right text-red-400 md:mt-2">
          {t('Expiration date is not valid')}
        </div>
      )}
    </div>
  );
};

export default PaymentCard;
